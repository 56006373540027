<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-card style="height:500px">
          <AnnounceKit
            :embed-widget="true"
            widget="https://announcekit.co/widgets/v2/3augH6"
          />
        </b-card>

      </b-col>
      <b-col md="4">
        <b-card style="height:500px">
          <AnnounceKit
            :embed-widget="true"
            widget="https://announcekit.co/widgets/v2/3dTsfS"
          />
        </b-card>

      </b-col>
    </b-row>

  </div>
</template>

<script>
import AnnounceKit from 'announcekit-vue'

export default {
  components: {
    AnnounceKit,
  },
  data() {
    return {
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  beforeMount() {
    this.LoadKey()
  },
  mounted() {
    this.LoadingEnd()
  },
  methods: {
    LoadKey() {
      this.$http.defaults.headers.common = { 'api-key': this.$store.state.user.AppActiveUser.Id }
    },
  },
}
</script>
